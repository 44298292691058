import {Component, OnInit} from '@angular/core';
import {CustomerService} from '../../../domain/customer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {delay} from '../../../utils/promise.utils';

@Component({
  selector: 'app-vipps-auth',
  templateUrl: './vipps-auth.component.html',
  styleUrls: ['./vipps-auth.component.sass'],
})
export class VippsAuthComponent implements OnInit {
  returnPath?: string | null;
  errorMessage = 'Access denied';

  constructor(private customerService: CustomerService,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private translate: TranslateService,
  ) {
  }

  async ngOnInit() {
    const state = this.route.snapshot.queryParamMap.get('state'); // `state` contains the return URL and the chain ID
    const code = this.route.snapshot.queryParamMap.get('code'); // Auth token
    const error = this.route.snapshot.queryParamMap.get('error');
    const errorDescription = this.route.snapshot.queryParamMap.get('error_description');

    if (state) {
      this.returnPath = new URL(state, window.location.origin).pathname;
    }

    if (errorDescription) {
      this.errorMessage = decodeURIComponent(errorDescription);
    }

    if (error == 'access_denied') {
      this.toastr.warning(this.errorMessage, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      await this.resumeUri();
      return;
    }

    if (code == null || state == null) {
      this.toastr.error('Vipps login failed', undefined, {disableTimeOut: true, easeTime: 100, positionClass: 'toast-bottom-center'});
      await this.resumeUri();
      throw new Error('Missing query parameter in Vipps auth URL');
    }

    const returnURI = new URL(state, window.location.origin);
    const params = new URLSearchParams(returnURI.search);
    const storeChainId = params.get('chainId');
    params.delete('chainId');
    this.returnPath = `${returnURI.pathname}?${params.toString()}`;

    if (!storeChainId?.length) {
      this.toastr.error('Vipps login failed', undefined, {disableTimeOut: true, easeTime: 100, positionClass: 'toast-bottom-center'});
      await this.resumeUri();
      throw new Error("Missing chain ID in 'state' parameter in Vipps auth URL");
    }

    await this.customerService.customerVippsAuthenticate(storeChainId, code);
    await this.resumeUri();
    await delay(500);
    this.toastr.success(
      await this.translate.get('LOGIN.loggedIn').toPromise(),
      undefined,
      {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'},
    );
  }

  async resumeUri() {
    if (this.returnPath) {
      await this.router.navigateByUrl(this.returnPath, {replaceUrl: true});
    } else {
      await this.router.navigateByUrl('/', {replaceUrl: true});
    }
  }
}
